import { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './DeleteModal.scss'
import { useDispatch, useSelector } from 'react-redux';
import { createNotification } from '../../../../Config/NotificationToast';
import { addEditUserManual } from '../../../../redux/actions/UserManualAction/UserManualAction';
import { CircularProgress } from '@mui/material';

function DeleteModal({onDelete, show, setShow, type}) {
  const dispatch = useDispatch()
  const {deleteLoader} = useSelector(state => state?.addUpdateUserManual);

  const handleClose = () => {
    setShow(false); 
  };

  const handleDelete = ()=>{
    onDelete?.()
  }


  return (
    <>
      <Modal show={show} 
        onHide={handleClose} 
        centered
    >
        <div className='delete-manual-modal py-4'>
            <div className="heading mb-2 px-3">
                Delete Manual
            </div>
            <hr className='m-0' />
            <div className='p-3 py-4 content'>
                Do you want to delete this manual?
             </div>
            <div className='d-flex justify-content-between pb-0 p-3'>
                <button variant="secondary" className='greenButton' onClick={handleClose} disabled={deleteLoader}>
                    CANCEL
                </button>
                <button variant="primary" className='redButton' onClick={handleDelete} disabled={deleteLoader}>
                  {
                    deleteLoader ?
                    <CircularProgress size={25} sx={{color: "red"}} />
                    :
                    'DELETE'
                  }
                </button>
            </div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteModal;