import { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './AddEditManual.scss'

import { useDispatch, useSelector } from 'react-redux';
import { createNotification } from '../../../../Config/NotificationToast';
import { addEditUserManual } from '../../../../redux/actions/UserManualAction/UserManualAction';
import {ReactComponent as UploadBtn } from '../../../../assets/images/upload.svg'
import {ReactComponent as FileIcon } from '../../../../assets/images/file.svg'
import {ReactComponent as RemoveBtn } from '../../../../assets/images/CommonComponent/redCross.svg'
import { CircularProgress } from '@mui/material';
import CircularProgressWithLabel from '../../../../components/CircularProgressWithLabel ';
import { Link } from 'react-router-dom';

function AddEditManualModal({imgURL, manualData, show, setShow, type}) {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState({title: manualData?.label || "", file: ""});
  const dispatch = useDispatch()
  const cropRef = useRef(null);
  const {updateLoader} = useSelector(state => state?.addUpdateUserManual);

  const handleClose = () => {
    setShow(false); 
  };

  

  const handleSave = async() => {
    if(!data.title){
      createNotification('warning', "Please fill the title.");
    }else if(type!=='edit' && !data.file){
      createNotification('warning', "Please Upload the manual.");
    } else{
      dispatch(addEditUserManual({
        id: manualData?.id || 0,
        ...data,
        path: manualData?.doc_path,
        setProgress,
        onSuccessCallback: ()=>{
          setShow(false)
        }
      }))
    }
  };

  return (
    <>
      <Modal show={show} 
        onHide={handleClose} 
        centered
    >
        <div className='add-edit-manual-modal py-4'>
            <div className="heading mb-2 px-3">
                {type==='add' ? 'Add Manual': 'Edit Manual'}
            </div>
            <hr className='m-0' />
            <div className='p-3'>
                <div className="mb-3 pr-0">
                    <label htmlFor="exampleFormControlInput1" className="form-label Label">Title</label>
                    <input
                        className="form-control Title"
                        placeholder="Enter "
                        value={data.title}
                        autoComplete='Off'
                        name='title'
                        onChange={(e)=> {setData(prev=> ({...prev, title: e.target.value}))}}
                    />
                </div>
                {
                  type==='edit' &&
                  <div className="mt-4 pr-0 d-flex">
                    <label htmlFor="exampleFormControlInput1" className="form-label Label mr-3">Uploaded File:</label>
                    {
                      manualData?.url &&
                      <Link target="_blank " className='d-flex file-link align-items-center text-decoration-none mb-2 fw-semi-bold' to={manualData.url}>
                        <FileIcon width={13} /> &nbsp;
                        {manualData.doc_path?.split('-').slice(1).join('-')}
                      </Link>
                    }
                  </div>
                }
                <div className="mt-2 pr-0">
                    <label htmlFor="exampleFormControlInput1" className="form-label Label">Add File</label>
                    <UploadButton
                      showProgress={updateLoader}
                      file={data.file}
                      setFile={(file)=> setData(prev=> ({...prev, file}))}
                      progress={progress}
                    />
                </div>
             </div>
            <div className='d-flex justify-content-between pb-0 p-3'>
                <button variant="secondary" className='redButton' onClick={handleClose} disabled={updateLoader}>
                    CANCEL
                </button>
                <button variant="primary" className='greenButton' onClick={handleSave} disabled={updateLoader}>
                  {
                    updateLoader ?
                    <CircularProgress size={25} sx={{color: "#31C52B"}} />
                    :
                      (type==='add' ? 'ADD': 'UPDATE')
                  }
                </button>
            </div>
        </div>
      </Modal>
    </>
  );
}

const UploadButton = ({file, setFile, progress, showProgress})=>{
  const inputFile = useRef(null);
  const onFileChange = (e)=>{
    if(e.target.files[0].type !="application/pdf"){
      createNotification("warning", "Only .pdf files are allowed.")
      return;
    }
    setFile(e.target.files[0])
  }

  useEffect(()=>{
    if(!file)
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
  }, [file])

  return (
    <div className='upload-btn-container'>
      <div className="font-semibold">
        <input
        ref={inputFile}
          multiple="multiple"
          className="d-none"
          name={"manual-file"}
          id={"manual-file"}
          type="file"
          accept=".pdf"
          onChange={onFileChange}
        ></input>
        <label
          htmlFor={"manual-file"}
          className="upload-multiple-files-btn d-flex align-items-center upload-btn">
          Browse &nbsp;&nbsp;
          <UploadBtn/>
        </label>

      </div>
      {
        file &&
        <div className="file-box shadow-sm bg-light d-flex justify-content-between my-3 p-3">
          <div className='file-name d-flex align-items-center'><FileIcon/> &nbsp;&nbsp;{file.name}</div>
          <div className='remove-btn' onClick={()=>setFile("")}>
            <div className='d-flex align-items-center'>
              {showProgress && <CircularProgressWithLabel  value={progress} height={30} width={30} fontSize={9} />}
            <RemoveBtn/>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default AddEditManualModal;