import { ADD_UPDATE_USER_MANUAL, DELETE_USER_MANUAL, GET_USER_MANUAL } from "../../constants";

export function viewUserManual(data) {
    return {
        type: GET_USER_MANUAL,
        data,
    };
}

export function addEditUserManual(data) {
    return {
        type: ADD_UPDATE_USER_MANUAL,
        data,
    };
}

export function deleteUserManual(data) {
    return {
        type: DELETE_USER_MANUAL,
        data,
    };
}