import * as type from '../../constants';

const initialState = {
    userManualList: [],
    updateLoader: false,
    deleteLoader: false,
    loading: false,
    error: null,
}

export function getUserManualList(state = initialState, action) {
    switch (action.type) {
        case type.GET_USER_MANUAL:
            return {
                ...state,
                loading: true,
            }
        case type.GET_USER_MANUAL_SUCCESS:
            return {
                ...state,
                userManualList: action.payload,
                loading: false,
                error: null
            }
        case type.GET_USER_MANUAL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}

export function addUpdateUserManual(state = initialState, action) {
    switch (action.type) {
        case type.ADD_UPDATE_USER_MANUAL:
            return {
                ...state,
                updateLoader: true,
            }
        case type.ADD_UPDATE_USER_MANUAL_SUCCESS:
            return {
                ...state,
                updateLoader: false,
            }
        case type.ADD_UPDATE_USER_MANUAL_FAILURE:
            return {
                ...state,
                updateLoader: false,
            }

        case type.DELETE_USER_MANUAL:
            return {
                ...state,
                deleteLoader: true,
            }
        case type.DELETE_USER_MANUAL_SUCCESS:
            return {
                ...state,
                deleteLoader: false,
            }
        case type.DELETE_USER_MANUAL_FAILURE:
            return {
                ...state,
                deleteLoader: false,
            }

        default:
            return state
    }
}




