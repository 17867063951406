import { call, put, takeEvery } from 'redux-saga/effects'
import { deleteUserManual_API, updateUserManual_API, } from '../../../Config/Api';
import { createNotification } from '../../../Config/NotificationToast';
import { logOut } from "../../../utils/Helper";
import { instance } from "../../auth/axiosInstance";
import { getUserManual_API } from '../../../Config/Api';
import { app } from '../../../firebase';
import { getDataFromFirebase } from '../../../Config/commonFirebaseImage';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
const storage = getStorage(app);
const metadata = { cacheControl: 'public, no-cache' };

export default function* UserManualSaga() {
    yield takeEvery('GET_USER_MANUAL', viewUserManual);
    yield takeEvery('ADD_UPDATE_USER_MANUAL', addUpdateUserManual);
    yield takeEvery('DELETE_USER_MANUAL', deleteUserManual);
}

const viewUserManualApi = async (url) => {
    try {
        const response = await instance.get(url);
            response.data = await Promise.all(response.data.map(async manual => {
            let manuals_url= await getDataFromFirebase(manual.doc_path)
            return {
                ...manual,
                url: manuals_url 
            };
        }))

        return response;
    } catch (errors) {
        console.log("errors", errors)
        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}
function* viewUserManual(action) {
    const URL = `${getUserManual_API}`
    try {
        const response = yield call(viewUserManualApi, URL);
        if (response.statusCode === 200) {
            yield put({ type: 'GET_USER_MANUAL_SUCCESS', payload: response.data });
        } else {
            if (response.statusCode === 401) {
                logOut('error', response.message)
            } else {
                createNotification('error', response.message);
                yield put({ type: 'GET_USER_MANUAL_FAILURE', message: response.message });
            }
        }
    } catch (e) {
        console.log(e)
        yield put({ type: 'GET_USER_MANUAL_FAILURE'});
    }
}

const addUpdateUserManualApi = async (data) => {
    try {
        const response = await instance.post(updateUserManual_API, {
            data,
        });
        return response;
    } catch (errors) {
        console.log("errors", errors);
        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}
function* addUpdateUserManual(action) {
    try {
        if(action.data.path){
            const fileRef = ref(storage, action.data.path);
            deleteObject(fileRef)
            .then(() => {
            })
            .catch((error) => {
                createNotification('error', "Error in deleting file");
            });

        }

        const filePath = `/docs/${Date.now()}-${action.data.file.name?.replace(/\s/g, '')}`;
        const fileRef = ref(storage, filePath);
        const uploadTask = uploadBytesResumable(fileRef, action.data.file, metadata);
    
        const uploadFile = () => {
            return new Promise((resolve, reject) => {
                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        action.data.setProgress(progress);
                    },
                    (error) => {
                        reject(""); 
                    },
                    async () => {
                        try {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            resolve(downloadURL); 
                        } catch (error) {
                            reject(""); 
                        }
                    }
                );
            });
        };
    
        const downloadURL = yield call(uploadFile);
        if(downloadURL){
            const BODY = {
                "id": action.data.id || 0,
                "label": action.data.title,
                "path": filePath,
                "memetype": "pdf",
            };
            const data = BODY;
            const response = yield call(addUpdateUserManualApi, data);
        
            if (response.statusCode === 200) {
                yield put({ type: 'ADD_UPDATE_USER_MANUAL_SUCCESS', payload: {...BODY, url: downloadURL } });
                createNotification('success', response.message);
                action?.data.onSuccessCallback();

                const viewResponse = yield call(viewUserManualApi, getUserManual_API);
                if (viewResponse.statusCode === 200) {
                    yield put({ type: 'GET_USER_MANUAL_SUCCESS', payload: viewResponse.data });
                }
            } else {
                if (response.statusCode === 401) {
                    logOut('error', response.message);
                } else {
                    createNotification('error', response.message);
                    yield put({ type: 'ADD_UPDATE_USER_MANUAL_FAILURE', message: response.message });
                }
                
            }
        }else{
            yield put({ type: 'ADD_UPDATE_USER_MANUAL_FAILURE', message: "Something went wrong." });
        }
    } catch (e) {
        console.log(e);
        yield put({ type: 'ADD_UPDATE_USER_MANUAL_FAILURE', message: e.message });
    }
}

const deleteUserManualApi = async (data) => {
    
    try {
        const response = await instance.delete(`${deleteUserManual_API}/${data.id}`);
        return response;
    } catch (errors) {
        console.log("errors", errors)
        if (
            errors.response.data.statusCode === 400) {
            createNotification('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            createNotification('error', errors.response.data.message);
        } else {
            createNotification('warning', "Something went wrong");
        }
        return errors
    }
}
function* deleteUserManual(action) {
    try {
        if(action.data.doc_path){
            const fileRef = ref(storage, action.data.doc_path);
            deleteObject(fileRef)
            .then(() => {
            })
            .catch((error) => {
                createNotification('error', "Error in deleting file");
            });

        }
        const response = yield call(deleteUserManualApi, {...action.data});

        if (response.statusCode === 200) {
            yield put({ type: 'DELETE_USER_MANUAL_SUCCESS', deleteTeamMember: response });
            createNotification('success', response.message);
            const viewResponse = yield call(viewUserManualApi, getUserManual_API);
            if (viewResponse.statusCode === 200) {
                yield put({ type: 'GET_USER_MANUAL_SUCCESS', payload: viewResponse.data });
            }
        } else {
            if (response.statusCode === 401) {
                logOut('error', response.message)
            } else {
                createNotification('error', response.message);
                yield put({ type: 'DELETE_USER_MANUAL_FAILURE', message: response.message });
                }
        }
    } catch (e) {
        yield put({ type: 'DELETE_USER_MANUAL_FAILURE', message: e.message });
    }
}


