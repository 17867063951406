import React, { useEffect, useState } from 'react'
import AppContainer from '../../components/AppContainer/AppContainer'
import './UserManual.scss'
import { Box, CircularProgress, Paper } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from 'reactstrap';
import WhitePlus from "../../assets/images/CommonComponent/WhitePlus.svg"
import AddEditManualModal from './components/AddEditManual/AddEditManual';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserManual, viewUserManual } from '../../redux/actions/UserManualAction/UserManualAction';
// import EditIcon from "../../assets/images/socialSharing"
import DeleteIcon from "../../assets/images/CommonComponent/trash.svg"
import moment from 'moment';
import { Link } from 'react-router-dom';
import { downloadFileFromURL } from '../../utils/Helper';
import DeleteModal from './components/DeleteModal/DeleteModal';

// const UserManualList=[
//     {id: 1, title: "Admin", updatedOn: "", filePath:"", fileURL: ""},
//     {id: 2, title: "Wellness Web + Wellness App", updatedOn: "", filePath:"", fileURL: ""},
//     {id: 3, title: "Guest", updatedOn: "", filePath:"", fileURL: ""},
// ]

const UserManual = (props) => {
    const dispatch = useDispatch()
    const[showAddModal, setShowAddModal] = useState(false);
    const {loading, userManualList} = useSelector(state => state?.getUserManualList);

    useEffect(()=>{
        dispatch(viewUserManual());
    }, [])

    return (
        <AppContainer>
            <div className="UserManual">
                <div className=''>
                    
                    <Box>
                        <Paper>
                            <div className="heading d-flex justify-content-between align-items-center p-3">
                                <span>User Manual</span>
                                <Button className="btn btn-primary addContent " type="submit" onClick={()=>setShowAddModal(true)}>
                                    <img className="mr-2 mb-1 " src={WhitePlus} alt="" width="14px" height="14px" />Upload Manual
                                </Button>
                            </div>
                            <hr className='m-0' />
                            <TableContainer  sx={{ minHeight: 400 }}  className='px-3'>
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Manual</TableCell>
                                        <TableCell width={"30%"}>Created On / Updated On</TableCell>
                                        <TableCell width={"360px"}>Action</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    {
                                        loading ?
                                        <TableBody>
                                            <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell colSpan={3} >
                                                <div className='text-center'><CircularProgress sx={{'color': '#74613C'}}/></div>
                                            </TableCell>
                                            
                                            </TableRow>
                                        </TableBody>
                                        :
                                        <TableBody>
                                        {userManualList.map((row) => (
                                            <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell sx={{padding: '5px 20px'}} >
                                                {row.label}
                                            </TableCell>
                                            <TableCell sx={{padding: '5px 20px'}}>{row.updated_on ? moment(row.updated_on).format("DD/MM/YYYY") : '-'}</TableCell>
                                            <TableCell sx={{padding: '5px 20px'}}>
                                                <Action
                                                    data={row}
                                                />
                                            </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    }
                                </Table>
                                </TableContainer>
                        </Paper>
                    </Box>
                </div>
                {
                    showAddModal &&
                    <AddEditManualModal
                        type='add'
                        show={showAddModal}
                        setShow={setShowAddModal}
                    />
                }
            </div>
        </AppContainer >
    )
}

export default UserManual

const Action = ({data})=>{
    const dispatch = useDispatch()
    const[showAddModal, setShowAddModal] = useState(false);
    const[showDeleteModal, setShowDeleteModal] = useState(false);
    const onDelete = ()=>dispatch(deleteUserManual({...data}))

    return (
        <div className='action-box'>
            <span><Link style={{color: "#74613C"}} className='mr-3 text-decoration-none' to={data.url} target='_blank'><i className="fas fa-eye mr-1"></i> View</Link></span>
            {/* <span style={{color: "#74613C"}}  className='mr-3 text-decoration-none' onClick={()=>{downloadFileFromURL(data.url, data.doc_path?.split('-').slice(1).join('-'))}} ><i class="fas fa-regular fa-circle-down"></i> Download</span> */}
            <span style={{color: "#74613C"}} className='mr-3' onClick={()=> setShowAddModal(true)} ><i className="fa fa-pen mr-1"  ></i> Edit</span>
            <span style={{color: "red"}} onClick={()=>setShowDeleteModal(true)} ><img src={DeleteIcon} className="mr-1 mb-1" alt="Avatar" width="14px" height="16px" /> Delete</span>
            {
                showAddModal &&
                <AddEditManualModal
                    manualData={data}
                    type='edit'
                    show={showAddModal}
                    setShow={setShowAddModal}
                />
            }

            {
                showDeleteModal &&
                <DeleteModal
                    show={showDeleteModal}
                    setShow={setShowDeleteModal}
                    onDelete={onDelete}
                />
            }
        </div>
    )
}